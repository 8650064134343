<template>
  <div class="carousel">
    <div class="title flex items-center justify-between">
      <slot name="title">
        <component :is="props.titleTag" class="text-xl font-500 my-3 md:text-2xl">
          {{ props.title }}
        </component>
      </slot>
      <client-only>
        <div class="actions h-content flex order-last items-center gap-3">
          <nd-button
            v-show="props.actionButton"
            class="my-3"
            :class="[(!showNavigator || swiper?.isLocked || !app.isDesktopScreen) && '-mr-3']"
            type="text"
            size="small"
            @click="$emit('clickActionButton')"
          >
            {{ props.actionButtonText }}</nd-button
          >
          <div v-show="showNavigator && app.isDesktopScreen && !swiper?.isLocked" class="flex gap-3 -mr-1.5">
            <nd-button :id="prevBtnId" type="icon" size="small" icon="arrow-left" :disabled="isPrevBtnDisabled" @click="slidePrev" />
            <nd-button :id="nextBtnId" type="icon" size="small" icon="arrow-right" :disabled="isNextBtnDisabled" @click="slideNext" />
          </div>
        </div>
      </client-only>
    </div>
    <client-only>
      <swiper-container
        ref="swiperRef"
        class="sm:mr-0 my-4 <sm:-mx-4"
        v-bind="swiperOptions"
        :init="false"
        @afterinit="handleSwiperInit"
        @slidechange="handleSlideChange"
      >
        <swiper-slide v-for="item in props.data" :key="item" class="overflow-hidden <sm:first:ml-4 <sm:last:mr-4">
          <slot :item="item">
            <div class="slider-wrapper max-w-100% w-360px h-135px">
              <div class="w-full h-full flex justify-center items-center bg-gray-30 border rounded flex-0">Slide {{ item }}</div>
            </div>
          </slot>
        </swiper-slide>
      </swiper-container>
      <template #fallback>
        <slot name="skeleton">
          <div class="skeleton-wrapper flex gap-4 py-4 overflow-hidden">
            <div v-for="i in 4" :key="i" class="skeleton-card">
              <n-skeleton class="w-360px h-135px rounded" />
              <n-skeleton class="mt-2 rounded" text />
              <n-skeleton class="mt-2 rounded w-60%" text />
            </div>
          </div>
        </slot>
      </template>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import type { Swiper } from 'swiper'
import { NSkeleton } from 'naive-ui'

import { useAppStore } from '@/store/app'
import { getRandomString } from '@/utils/common'

const emit = defineEmits(['clickActionButton', 'slideChange'])

const props = withDefaults(
  defineProps<{
    title?: string
    titleTag?: string
    data?: readonly any[]
    actionButton?: boolean
    actionButtonText?: string
    navigator?: boolean
    autoplay?: boolean
    autoplayDelay?: number
    loop?: boolean
    gap?: number
  }>(),
  {
    data: () => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    title: '',
    titleTag: 'h2',
    actionButton: false,
    actionButtonText: () => useI18n().t('all_check_more'),
    navigator: false,
    autoplay: false,
    autoplayDelay: 5000,
    loop: false,
  }
)

const app = useAppStore()
const swiperRef = ref<HTMLElement>()
const swiper = ref<Swiper>()

const prevBtnId = `prev-btn-${getRandomString()}`
const nextBtnId = `next-btn-${getRandomString()}`

const gap = computed(() => {
  if (typeof props.gap === 'number') return props.gap
  return app.isDesktopScreen ? 20 : 8
})
const showNavigator = computed(() => props.navigator)

const swiperOptions = computed(() => ({
  slidesPerView: 'auto',
  slidesPerGroup: 1,
  spaceBetween: gap.value,
  centeredSlides: false,
  pagination: false,
  speed: 300,
  cssMode: true,
  observer: true,
  freeMode: true,
  autoplay: props.autoplay && { delay: props.autoplayDelay },
  rewind: props.loop && props.data.length > 1,
  eventsPrefix: '',
}))

const isBeginning = ref(true)
const isEnd = ref(false)

const isPrevBtnDisabled = computed(() => !swiperOptions.value.rewind && isBeginning.value)
const isNextBtnDisabled = computed(() => !swiperOptions.value.rewind && isEnd.value)

const renewAutoplay = () => {
  if (!swiper.value || !props.autoplay) return
  swiper.value.autoplay.stop()
  swiper.value.autoplay.start()
}

const handleSwiperInit = (e: CustomEvent) => {
  const swiperInstance = e.detail[0]
  swiper.value = swiperInstance
}

const handleSlideChange = () => {
  if (!swiper.value) return

  renewAutoplay()
  setTimeout(() => {
    isBeginning.value = swiper.value!.isBeginning
    isEnd.value = swiper.value!.isEnd
  }, 200)
  emit('slideChange', swiper.value.activeIndex)
}

const slidePrev = () => {
  swiper.value?.slidePrev()
}

const slideNext = () => {
  swiper.value?.slideNext()
}

watch(
  swiperOptions,
  () => {
    if (!swiperRef.value || !swiper.value) return

    // Object.assign(swiperRef.value, option)
    swiper.value.update()
  },
  { deep: true }
)

onMounted(() => {
  nextTick(() => {
    if (swiperRef.value) {
      const swiperContainerEl = swiperRef.value as HTMLElement & {
        initialize: () => void
      }

      Object.assign(swiperContainerEl, swiperOptions.value)

      swiperContainerEl.initialize()
    } else {
      throw createError({
        message: `Could not create carousel. Missing DOM element <swiper-container />.`,
        statusCode: 500,
        statusMessage: `Internal Server Error`,
      })
    }
  })
})

onBeforeUnmount(() => {
  if (swiper.value) {
    swiper.value.destroy()
  }
})
</script>

<style lang="scss" scoped>
swiper-slide {
  @apply w-fit;
}
</style>
